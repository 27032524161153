import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';

import {
  Cluster,
  Stack
} from '../EveryLayout'
import {
  FormButton,
  Spinner
} from '../Page';
import { Modal } from '../Misc/Modal';

const NewsletterSignup = ({ children }) => {

  const data = useStaticQuery(graphql`
    query {
      image: file(name: {eq: "Abler Nordic India-27"}) {
        childImageSharp {
          gatsbyImageData(width: 800, aspectRatio: 0.75)
        }
      }
    }
  `);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [waiting, setWaiting] = useState(false);

  // setShowModal(false) to dismiss modal
  const [showModal, setShowModal] = useState(false);
  const emailField = useRef(null);

  async function signup(e) {
    e.preventDefault()
    setWaiting(true);

    const form = e.target;
    const body = JSON.stringify({
      email: form.email.value,
    });

    const doRequest = async () => {
      const response = await fetch('/api/newsletter', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body,
        })
        .then((res) => {
          setWaiting(false);
          return res.json();
        });
      setMessage(response.message);
      setError(!response.ok);

      // clear subscribe field
      if (emailField && emailField.current) {
        emailField.current.value = '';
      }
    }

    doRequest();
  }

  const modalOpenState = (state) => {
    setShowModal(state);
    setMessage('');
    setError(false);
  }

  const timerHandle = useRef(null);

  const [ showSpinner, setShowSpinner ] = useState(false);

  useEffect(() => {
    if (waiting) {
      timerHandle.current = setTimeout(() => setShowSpinner(true), 500);
    } else {
      clearTimeout(timerHandle.current);
      setShowSpinner(false);
    }
  }, [waiting]);


  return (
    <>
      <div onClick={() => setShowModal(true)}>
        {children}
      </div>

      <Modal
        open={showModal}
        handleSetOpen={modalOpenState}
        title="Newsletter"
        image={data.image}
        className="lg:max-w-4xl xl:max-w-5xl"
      >
        <Stack style={{ '--space': 'var(--space-8)' }}>
          <div className="prose prose-custom">
            <p>
              Sign up for news and updates from Abler Nordic.
            </p>
          </div>

          <form onSubmit={signup} method="POST" action="/api/newsletter">
            <Stack>
              <Cluster className="mb-6 flex-row">
                <div className="">
                  <label
                    htmlFor="email"
                    className="text-[1.125rem] sm:text-[1.3125rem]"
                  >
                    Email:
                  </label>
                </div>
                <div className="basis-[100%] sm:basis-0 flex-1">
                  <input
                    type="text"
                    id="email"
                    ref={emailField}
                    aria-describedby={ error ? 'subscription-error' : '' }
                    aria-invalid={error}
                    className="w-full sm:w-full !bg-transparent p-1 border-0 border-b !border-soft-black text-[1.125rem] sm:text-[1.3125rem]"
                  />

                </div>
              </Cluster>

              <div className="prose prose-custom">
                {error && (
                  <div
                    id="subscription-error"
                    aria-live="polite"
                    className="text-xs text-red inline-flex"
                  >
                    <span className="mr-1"><ExclamationCircleIcon className="flex-shrink-0 h-8 w-8 text-red" aria-hidden="true" /></span>{message}
                  </div>
                )}

                {(!error && !!message) && (
                  <div
                    aria-live="polite"
                    className="text-xs text-dark-green inline-flex"
                  >
                    <span className="mr-1"><CheckCircleIcon className="flex-shrink-0 h-8 w-8 text-light-green" aria-hidden="true" /></span>{message}
                  </div>
                )}
              </div>

              <Cluster>
                <FormButton>
                  Subscribe
                </FormButton>
                <div>
                  {showSpinner
                    && (
                      <Spinner />
                    )
                  }
                </div>
              </Cluster>
            </Stack>
          </form>
        </Stack>
      </Modal>

    </>
  );
}

export default NewsletterSignup;
